
import { ObjectContextMenu } from "./ObjectContextMenu";
import { logger } from "../logger/Logger";


/**
 * Constructs a ViewerObjectContextMenu object.
 * @param {Viewer} viewer
 * @constructor
 */
export function ViewerObjectContextMenu(viewer) {
    ObjectContextMenu.call(this, viewer);
}

ViewerObjectContextMenu.prototype = Object.create(ObjectContextMenu.prototype);
ViewerObjectContextMenu.prototype.constructor = ViewerObjectContextMenu;

/**
 * Builds the context menu to be displayed.
 * @override
 * @param {Event} event - Browser event that requested the context menu
 * @param {Object} status - Information about nodes: numSelected, hasSelected, hasVisible, hasHidden.
 * @returns {?Array} An array of menu items.
 */
ViewerObjectContextMenu.prototype.buildMenu = function (event, status) {

    // Context menu varies depending on whether we show 2D or 3D models. If we have neither 2d nor 3d, just don't create it.
    if (!this.viewer.model) {
        return;
    }

    var that = this,
        menu = [],
        nav = this.viewer.navigation,
        is2d = this.viewer.model.is2d();

    // the title strings here are added to the viewer.loc.json for localization
    if (status.hasSelected) {
        menu.push({
            title: "Isolate",
            target: function () {  
                var selection = that.viewer.getAggregateSelection();
                that.viewer.impl.visibilityManager.aggregateIsolate(selection);
                that.viewer.clearSelection();
                logger.track({name: 'isolate_count', aggregate: 'count'});
            }
        });
        if (status.hasVisible) {
            menu.push({
                title: "Hide Selected",
                target: function () {
                    var visMan = that.viewer.impl.visibilityManager;
                    that.viewer.getAggregateSelection(function(model, dbId) {
                        visMan.hide(dbId, model);
                    });
                    that.viewer.clearSelection();
                }
            });
        }
        if (status.hasHidden) {
            menu.push({
                title: "Show Selected",
                target: function () {
                    // This is such a weird use case. Users can't select hidden nodes.
                    // For this to work, selection must have been done through code.
                    var selected = that.viewer.getSelection();
                    that.viewer.clearSelection();
                    that.viewer.show(selected);
                }
            });
        }
    }

    if (is2d) {
        menu.push({
            title: "Show All Layers",
            target: function () {
                that.viewer.setLayerVisible(null, true);
            }
        });
    }

    menu.push({
        title: "Show All Objects",
        target: function () {
            that.viewer.showAll();
            that.viewer.getExtension("Autodesk.Section", (ext) => {
                ext.deactivate();
            });
            logger.track({ name: 'showall', aggregate: 'count' });
        }
    });


    // Fit-to-view only work with selections from one model.
    var aggregateSelection = that.viewer.getAggregateSelection();
    if (!is2d && aggregateSelection.length === 1 && nav.isActionEnabled('gotoview')) {
        menu.push({
            title: "Focus",
            target: function () {
                aggregateSelection = that.viewer.getAggregateSelection(); // Get the aggregate selection again
                if (aggregateSelection.length > 0){
                    var singleRes = aggregateSelection[0];
                    that.viewer.fitToView(singleRes.selection, singleRes.model);
                } else if (aggregateSelection.length === 0) {
                    that.viewer.fitToView(); // Fit to whole model, the first one loaded.
                }
                logger.track({ name: 'fittoview', aggregate: 'count' });
            }
        });
    }

    // Pivot point
    if (!is2d) {
        var rect = this.viewer.impl.getCanvasBoundingClientRect();
        var canvasX = event.clientX - rect.left;
        var canvasY = event.clientY - rect.top;
        var res = this.viewer.clientToWorld(canvasX, canvasY, false);
        if (res) {
            menu.push({
                title: "Pivot",
                target: () => {
                    this.viewer.navigation.setPivotPoint(res.point);
                }
            });
        }
    }

    if (status.hasSelected) {
        menu.push({
            title: "Clear Selection",
            target: function () {
                that.viewer.clearSelection();
                logger.track({ name: 'clearselection', aggregate: 'count' });
            }
        });
    }

    const section = this.viewer.getExtension('Autodesk.Section');
    if (section && status.hasSelected) {
        const bbox = that.viewer.impl.selector.getSelectionBounds();
        menu.push({
            title: 'Section Box',
            target: section.setSectionBox.bind(section, bbox)
        });
        const selected = that.viewer.getSelection();
        const rect = this.viewer.impl.getCanvasBoundingClientRect();
        const canvasX = event.clientX - rect.left;
        const canvasY = event.clientY - rect.top;
        const intersection = that.viewer.impl.hitTest(canvasX, canvasY, false, selected);
        // Insure that the selected object is the on that recieved the context click.
        if (intersection && selected.indexOf(intersection.dbId) !== -1) {
            menu.push({
                title: 'Section Plane',
                target: section.setSectionPlane.bind(section, intersection.face.normal, intersection.point)
            });
        }
    }

    return menu;
};
