import { endpoint } from "./endpoints";

/**
 * @typedef ParsedId
 * @property {URL} url The dummy url to the property
 * @property {string} branch The urn of the branch containing the property
 * @property {string} type The type of the property, can be "derivatives", "manifest"
 *  or "thumbnails".
 * @property {string[]} path The path from the root of branch to the property.
 *  Each entry in the array is the name of a property along the path.
 */

const parse = /^.*\/(((derivatives)(\/urn:))|((manifest|thumbnails)\/))([^/]+)(\/(.*))?$/;
/**
 * Extract the relevant data from a url string for data from HFDM
 * @param {string} url The url to parse
 * @param {ViewingService} ViewingService The Viewing Service
 * @param {string} [api] The api to use for the url - defaults to derivatives
 * @param {string} [baseUrl] The base url to use - defaults to the configured endpoint
 * @return {ParsedId} The extracted data
 */
export function parseHfdmUrl(url, ViewingService, api, baseUrl) {
    let urnLoc;
    if (endpoint.getApiFlavor().indexOf("hfdm") !== 0 ||
        (urnLoc = url.indexOf("urn:")) === -1 ||
        (urnLoc !== 0 && url[urnLoc - 1] !== '/')) {
        return null;
    }

    api = api || "derivatives";
    baseUrl = baseUrl || endpoint.getApiEndpoint();
    url = ViewingService.generateUrl(baseUrl, api, url)
    
    // Create a URL object from the url
    const parsed = new URL(url);
    // Extract the data type, branch urn and path to the property
    const path = parse.exec(decodeURIComponent(parsed.pathname));
    // If no match, or no data type or no branch urn, then fail
    if (!path || !(path[3] || path[6]) || !path[7])
        return null;
    let array;
    // Convert the property path to an array
    array = path[9] ? path[9].split('/') : [];

    return {
        url: parsed,
        branch: "urn:" + path[7],
        type: path[3] || path[6],
        path: array
    };
}
