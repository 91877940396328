
export function ProgressBar(container) {

    this.bg = document.createElement('div');
    this.bg.className = 'progressbg';

    this.fg = document.createElement('div');
    this.fg.className = 'progressfg';

    this.bg.appendChild(this.fg);
    container.appendChild(this.bg);

    this.lastValue = -1;

    this.fg.style.width = "0%";

};

ProgressBar.prototype.setPercent = function(pct) {

    if (pct == this.lastValue)
        return;

    this.lastValue = pct;

    if (pct >= 99) {
        this.bg.style.visibility = "hidden";
    } else {
        this.bg.style.visibility = "visible";
        this.fg.style.width = pct + "%";
    }
};

ProgressBar.prototype.setStyle = function(style) {
    !this.fg.classList.contains(style) && this.fg.classList.add(style);
};

ProgressBar.prototype.removeStyle = function(style) {
    this.fg.classList.contains(style) && this.fg.classList.remove(style);
};
