import { isMobileDevice } from '../compat';
import { DefaultLightPreset } from './LightPresets';
import { Prefs, Prefs2D, Prefs3D } from './PreferencesState';

// Contains settings for different profiles.

/**
 * Object used to apply the preferences by a Profile 
 * @typedef {Object} Settings
 * @property {boolean} viewCube - Sets the visibility of the viewcube.
 * @property {boolean} alwaysUsePivot - Orbit controls always orbit around the currently set pivot point.
 * @property {boolean} zoomTowardsPivot - default direction for camera dolly (zoom) operations to be towards the camera pivot point.
 * @property {boolean} reverseHorizontalLookDirection - Sets a view navigation option to reverse the default direction for horizontal look operations.
 * @property {boolean} reverseVerticalLookDirection - Sets a view navigation option to reverse the default direction for vertical look operations.
 * @property {boolean} orbitPastWorldPoles - Set a view navigation option to allow the orbit controls to move the camera beyond the north and south poles (world up/down direction).
 * @property {boolean} clickToSetCOI - Modify the default click behavior for the viewer.
 * @property {boolean} ghosting - Toggles ghosting during search and isolate.
 * @property {boolean} optimizeNavigation - Toggles whether the navigation should be optimized for performance.
 * @property {boolean} ambientShadows - Enables or disables ambient shadows.
 * @property {boolean} antialiasing - Enables or disables antialiasing.
 * @property {boolean} groundShadow - Toggles ground shadow.
 * @property {boolean} groundReflection - Toggles ground reflection.
 * @property {boolean} lineRendering - Hides all lines in the scene.
 * @property {boolean} edgeRendering - Turns edge topology display on/off (where available).
 * @property {number} lightPreset - Sets the Light Presets (Environments) for the Viewer.
 * @property {boolean} envMapBackground - Toggles environment map for background.
 * @property {boolean} firstPersonToolPopup - Toggles first person tool popup.
 * @property {boolean} bimWalkToolPopup - Toggles the bimwalk tool popup.
 * @property {boolean} grayscale - Overrides line colors in 2D models to render in shades of gray.
 * @property {boolean} swapBlackAndWhite - Will switch to white lines on a black background.
 * @property {boolean} progressiveRendering - Toggles whether progressive rendering is used.
 * @property {boolean} openPropertiesOnSelect - Open property panel when selecting an object (Only for GuiViewer3D).
 * @property {boolean} pointRendering - Hides all points in the scene.
 * @property {*} backgroundColorPreset - Sets a color to the background.
 * @property {boolean} reverseMouseZoomDir - Reverse the default direction for camera dolly (zoom) operations.
 * @property {boolean} leftHandedMouseSetup - Reverse mouse buttons from their default assignment (i.e. Left mouse operation becomes right mouse and vice versa).
 * @property {boolean} fusionOrbit - Sets the orbit to fusion orbit.
 * @property {boolean} fusionOrbitConstrained - Sets the the orbit to the contrained fusion orbit.
 * @property {boolean} wheelSetsPivot - Sets wheel-zoom action to automatically reset the orbit pivot to the location under the cursor.
 */

 /**
 * Contains information about which extension should or should not be loaded.
 * @typedef {Object} Extensions
 * @property {string[]} load - An array of extension ids that should be loaded.
 * @property {string[]} unload - An array of extension ids that should not be loaded.
 */

/**
 * Object used for setting a viewer profile.
 * @typedef {Object} ProfileSettings
 * @property {string} name - Name of the profile settings.
 * @property {Settings} settings - Used by the Profile to apply to the viewer preferences.
 * @property {Extensions} extensions - Extensions that should or should not be loaded.
 */

const defaultSettings = {};
defaultSettings[Prefs3D.AMBIENT_SHADOWS] = true;
defaultSettings[Prefs3D.ANTIALIASING] = !isMobileDevice();
defaultSettings[Prefs3D.GROUND_SHADOW] = true;
defaultSettings[Prefs3D.GROUND_REFLECTION] = false;
defaultSettings[Prefs3D.GHOSTING] = true;
defaultSettings[Prefs3D.VIEW_CUBE] = !isMobileDevice();
defaultSettings[Prefs3D.LINE_RENDERING] = true;
defaultSettings[Prefs3D.LIGHT_PRESET] = DefaultLightPreset;
defaultSettings[Prefs3D.EDGE_RENDERING] = BUILD_FLAG__AEC_NAVIGATION; // BUILD_FLAG__AEC_NAVIGATION is set by webpack
defaultSettings[Prefs3D.REVERSE_HORIZONTAL_LOOK_DIRECTION] = false;
defaultSettings[Prefs3D.REVERSE_VERTICAL_LOOK_DIRECTION] = false;
defaultSettings[Prefs3D.ALWAYS_USE_PIVOT] = BUILD_FLAG__AEC_NAVIGATION;
defaultSettings[Prefs3D.ZOOM_TOWARDS_PIVOT] = false;
defaultSettings[Prefs3D.ORBIT_PAST_WORLD_POLES] = true;
defaultSettings[Prefs3D.CLICK_TO_SET_COI] = false;
defaultSettings[Prefs3D.OPTIMIZE_NAVIGATION] = isMobileDevice();
defaultSettings[Prefs3D.ENV_MAP_BACKGROUND] = false;
defaultSettings[Prefs3D.FIRST_PERSON_TOOL_POPUP] = true;
defaultSettings[Prefs3D.BIM_WALK_TOOL_POPUP] = true;

// Settings for 2D
defaultSettings[Prefs2D.GRAYSCALE] = false;
defaultSettings[Prefs2D.SWAP_BLACK_AND_WHITE] = false;

// Settings that are shared between 2D and 3D
defaultSettings[Prefs.PROGRESSIVE_RENDERING] = true;
defaultSettings[Prefs.OPEN_PROPERTIES_ON_SELECT] = false;
defaultSettings[Prefs.POINT_RENDERING] = true;
defaultSettings[Prefs.BACKGROUND_COLOR_PRESET] = null;
defaultSettings[Prefs.REVERSE_MOUSE_ZOOM_DIR] = BUILD_FLAG__AEC_NAVIGATION;
defaultSettings[Prefs.LEFT_HANDED_MOUSE_SETUP] = false;
defaultSettings[Prefs.FUSION_ORBIT] = true;
defaultSettings[Prefs.FUSION_ORBIT_CONSTRAINED] = true;
defaultSettings[Prefs.WHEEL_SETS_PIVOT] = BUILD_FLAG__AEC_NAVIGATION;

/**
 * Default settings.
 * @type {Settings}
 * @alias Autodesk.Viewing.DefaultSettings
 */
export const DefaultSettings = defaultSettings;

const defaults = {};

// Settings for 3D
defaults.name = 'Default';

defaults.settings = defaultSettings;

defaults.extensions = {
    load: [],
    unload: []
}

/**
 * Default profile settings.
 * @type {ProfileSettings}
 * @alias Autodesk.Viewing.DefaultProfileSettings
 */
export const DefaultProfileSettings = defaults;

const aec = {};

aec.name = 'AEC';
aec.settings = {};
aec.settings[Prefs.REVERSE_MOUSE_ZOOM_DIR] = true;
aec.settings[Prefs.WHEEL_SETS_PIVOT] = true;
aec.settings[Prefs3D.EDGE_RENDERING] = true;
aec.settings[Prefs3D.ALWAYS_USE_PIVOT] = true;

aec.extensions = {
    load: [],
    unload: []
};

/**
 * Profile settings for AEC models.
 * @type {ProfileSettings}
 * @alias Autodesk.Viewing.AECProfileSettings
 */
export const AECProfileSettings = aec;
